// Helper Library
(function(helper, $) {
    // This is now a utility function to "Get the Document Hash"
    helper.getDocumentHash = function (urlString) {
        var hashValue = "";

        if (urlString.indexOf('#') != -1) {
            hashValue = urlString.substring(parseInt(urlString.indexOf('#')) + 1);
        }
        return hashValue;
    };
})(this.helper = this.helper || {}, jQuery);


var __breakpoint = 767;

$(document).ready(function() {
	init_link();
	init_faq();
	// init_scroll();
});

//PCナビ　スクロールして現れる
$(window).on('scroll', function() {
	// init_scroll();
});

function init_scroll() {
	if($(window).width() <= __breakpoint) {
        if ($(window).scrollTop() > 200 && $(window).scrollTop() < $('#footer').offset().top - $(window).height()) {
        	$('#globBanner').fadeIn().css('display', 'flex');
        } else {
        	$('#globBanner').fadeOut();
        }
	}
}

function init_link () {
	$('.btn').click(function(e) {
		var hrefVal = $(this).attr('href');
		if(hrefVal && hrefVal != "") {
			var id = helper.getDocumentHash(hrefVal);
			if(id && $('#' + id).length > 0) {
				e.preventDefault();
				e.stopPropagation();
				if($('#globNaviCheck:checked')) {
					$('#globNaviCheck').trigger("click");
				}
			    $([document.documentElement, document.body]).animate({
			        scrollTop: $("#" + id).offset().top
			    }, 1000);
		    }
	    }
	});
}

function init_faq() {
	var aa = $('#faqList dt');
	$('.btn_accordion').click(function(){
		var parent = $(this).closest('.accordion');
		if(parent.hasClass('opened')) {
			parent.removeClass('opened');
		} else {
			parent.addClass('opened');
		}
	});
}